import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import ErrorLayout from './layouts/Error';
import AuthView from './views/Auth';
import AuthLayout from './layouts/Auth';

export default [
	// {
	// 	path: '/',
	// 	exact: true,
	// 	component: () => <Redirect to="/overview" />
	// },
	{
		path: '/errors',
		component: ErrorLayout,
		routes: [
			{
				path: '/errors/error-401',
				exact: true,
				component: lazy(() => import('src/views/Error401'))
			},
			{
				path: '/errors/error-404',
				exact: true,
				component: lazy(() => import('src/views/Error404'))
			},
			{
				path: '/errors/error-500',
				exact: true,
				component: lazy(() => import('src/views/Error500'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	},
	{
		path: '*',
		component: AuthLayout,
		routes: [
			{
				path: '/auth',
				exact: true,
				component: AuthView
			},
			{
				path: '/auth/:orderNo',
				exact: true,
				component: AuthView
			},
			{
				path: '/overview/:orderNo',
				exact: true,
				component: lazy(() => import('src/views/Overview'))
			},
			{
				path: '/overview/:orderNo/:status',
				exact: true,
				component: lazy(() => import('src/views/Overview'))
			},
			{
				component: () => <Redirect to="/errors/error-404" />
			}
		]
	}
];
