import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
	Container,
} from '@material-ui/core';

import Page from 'src/components/Page';

import { updateTransaction } from 'src/actions';

const locale = require('react-redux-i18n').I18n;

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3, 3)
	},
}));

const Auth = ({ match }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { orderNo } = match.params;

	const query = window.location.search;
	const params = new URLSearchParams(query);

	const redirectUrl = params.get('redirect_url');

	//	Functions

	useEffect(() => {
		//	Update transaction params
		dispatch(updateTransaction({ redirectUrl: decodeURI(redirectUrl) }));
		//	Set timeout
		setTimeout(() => {
			if (!orderNo) {
				history.replace('/errors/error-404');
			} else {
				history.replace(`/overview/${orderNo}`);
			}
		}, 3000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page
			className={classes.root}
			title={locale.t('overview.title')}
		>
			<Container maxWidth="lg">
				Processing...
			</Container>
		</Page>
	);
};

export default Auth;
