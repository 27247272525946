import * as actionTypes from 'src/actions';

const initialState = {
	redirectUrl: ''
};

const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_TRANSACTION: {
			const newState = { ...state };
			const { payload } = action;
			newState.redirectUrl = payload.redirectUrl;
			return {
				...newState
			};
		}

		default: {
			return state;
		}
	}
};

export default sessionReducer;
