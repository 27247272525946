import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000';

// 272537
// 606977
// D1DCE2

// E5D817

export default {
	primary: {
		contrastText: white,
		dark: '#606977', // colors.indigo[900],
		main: '#272537', // colors.indigo[500],
		light: '#D1DCE2', // colors.indigo[100]
	},
	secondary: {
		contrastText: black,
		dark: '#FFC100', // colors.blue[900],
		main: '#FFDC02', // colors.blue.A700,
		light: '#FCDAA5', // colors.blue.A400
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400]
	},
	text: {
		primary: colors.blueGrey[900],
		secondary: colors.blueGrey[600],
		link: colors.blue[600]
	},
	link: colors.blue[800],
	icon: colors.blueGrey[600],
	background: {
		default: '#F4F6F8',
		paper: white
	},
	divider: colors.grey[200]
};
