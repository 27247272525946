export default {
	app_name: 'WelaCoffee',
	btn: {
		yes: 'ใช่',
		no: 'ไม่ใช่',
		cancel: 'ยกเลิก',
		discard_changes: 'ยกเลิกการเปลี่ยนแปลง',
		save: 'บันทึก',
		close: 'ปิด',
		clear: 'ล้าง',
		applyFilters: 'บันทึกตัวกรอง',
		search: 'ค้นหา',
		delete: 'ลบ',
		signout: 'ออกจากระบบ',
		next: 'ถัดไป',
		previous: 'ก่อนหน้า',
		add: 'เพิ่ม',
		submit: 'Submit'
	},
	dialog: {
		title: 'ข้อความ',
		discard_changes_message: 'Discard any changes?',
		delete_data_message: 'Are you sure to delete this data?',
		remove_component_message: 'Are you sure to remove this component?'
	},
	overview: {
		stepper: {
			step1: {
				default: 'Confirm order & payment method',
				order: {
					header: 'Order no - %{orderNo}',
					sub_header: 'จำนวนสินค้าภายในคำสั่งซื้อนี้ %{count} ชิ้น',
				},
				payment: {
					header: 'ช่องทางการชำระสินค้า',
					sub_header: 'กรุณาเลือกวิธีการที่สะดวกที่สุดสำหรับคุณ ในการชำระเงิน',
					btn_action: 'เริ่มการชำระเงิน',
				},
			},
			step2: {
				default: 'Fill payment method details',
				credit: {
					header: 'ชำระเงินผ่านบัตรเครดิต/เดบิต',
					sub_header: 'กรอกรายละเอียดด้านล่างเพื่อดำเนินการชำระเงิน',
					card_holder: 'ชื่อผู้ถือบัตร',
					card_no: 'หมายเลขบัตร (ด้านหน้าบัตร)',
					card_no_placeholder: 'รหัสเลข 16 หลัก',
					expire: 'เดือน/ปีที่บัตรหมดอายุ',
					expire_placeholder: 'MM/YY',
					cvv: 'รหัส CVV (ด้านหลังบัตร)',
					cvv_placeholder: 'รหัสเลข 3 หลัก',
					btn_action: 'ชำระด้วยบัตรเครดิต/เดบิต',
					btn_back: 'เลือกวิธีการชำระเงินใหม่',
					save_fail: 'ข้อมูลบัตรเครดิตของคุณไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง',
					confirm_message: 'ข้อมูลบัตรเครดิตถูกต้องใช่หรือไม่?',
					validator: {
						card_holder: '^กรุณากรอกชื่อผู้ถือบัตร',
						card_no: '^กรุณากรอกหมายเลขบัตร',
						expire: '^กรุณากรอกเดือน/ปีที่บัตรหมดอายุ',
						cvv: '^กรุณากรอกรหัส CVV',
					},
				},
				header: 'สร้าง Line Notify Access Token',
				sub_header: 'เมื่อยืนยันตัวตนผ่าน Line แล้ว ขั้นตอนต่อไปคือการขอ Access Token สำหรับส่งข้อความผ่าน "LINE Notify"',
				btn_action: 'Generate Token'
			},
			step3: {
				default: 'Save Token to Account',
				header: 'บันทึกข้อมูล Access Token',
				sub_header: 'โปรดอย่าส่ง Access Token (แสดงด้านล่าง) ให้บุคคลอื่นๆ เนื่องจากข้อมูลนี้เป็นข้อมูลเฉพาะของคุณเองเท่านั้น',
				btn_action: 'Update Profile with Token',
				testing_message: 'เริ่มต้นการเชื่อมต่อ Line Notify'
			}
		},
	},
};
